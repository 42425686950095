<template>
    <div class="grid">
      <div class="col-12">
        <div class="card">
          <Toast />
          <Toolbar class="mb-4">
            <template v-slot:start>
              <div class="my-2">
                <Button label="Nova Chave" icon="pi pi-plus" class="p-button-success mr-2" @click="openNew" />
                <Button label="Apagar" icon="pi pi-trash" class="p-button-danger" @click="confirmDeleteSelected" :disabled="!selectUnidadesTipoTensao || !selectUnidadesTipoTensao.length" />
              </div>
            </template>
  
            <template v-slot:end>
              <!-- <FileUpload mode="basic" accept="image/*" :maxFileSize="1000000" label="Import" chooseLabel="Import" class="mr-2 inline-block" />-->
              <Button label="Exportar" icon="pi pi-upload" class="p-button-help" @click="exportCSV($event)" />
            </template>
          </Toolbar>
  
          <!-- Tabela de Unidades -->
          <DataTable ref="dt" :value="tiposTensoes" v-model:selection="selectUnidadesTipoTensao" dataKey="id" :paginator="true" :rows="50" :filters="filters" 
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5, 10, 25]"
           currentPageReportTemplate="Mostrando {first} de {last} para {totalRecords} resultado(s)" responsiveLayout="scroll">
            <template #header>
              <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                <h5 class="m-0">Gereneciar Chaves</h5>
                <span class="block mt-2 md:mt-0 p-input-icon-left">
                  <i class="pi pi-search" />
                  <InputText v-model="filters['global'].value" placeholder="Buscar" />
                </span>
              </div>
            </template>
  
            <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
            <Column field="id" header="ID" :sortable="true" headerStyle="width:14%; min-width:5rem;">
              <template #body="slotProps">
                <span class="p-column-title">Id</span>
                {{ slotProps.data.id }}
              </template>
            </Column>
  
            <Column field="descricao" header="Tipos Tensão" :sortable="true" headerStyle="width:30%; min-width:10rem;">
              <template #body="slotProps">
                <span class="p-column-title">Tipos Tensão</span>
                {{ slotProps.data.descricao }}
              </template>
            </Column>
  
            <Column headerStyle="min-width:10rem;">
              <template #body="slotProps">
                <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="editProduct(slotProps.data)" />
                <Button icon="pi pi-trash" class="p-button-rounded p-button-warning mt-2" @click="apagarTipoTensaoModal(slotProps.data)" />
              </template>
            </Column>
          </DataTable>
          
          <!-- Modal Adição/Edição de Tipo de tensão -->
          <Dialog v-model:visible="productDialog" :style="{ width: '450px' }" header="Editar Tipo de tensão" :modal="true" class="p-fluid">
            <div class="field">
              <label for="name">Tipo de tensão</label>
              <InputText id="bome" v-model.trim="tiposTensao.descricao" required="true" autofocus :class="{ 'p-invalid': submitted && !tiposTensao.descricao}" />
              <small class="p-invalid" v-if="submitted && !tiposTensao.descricao">Descrição é obrigatória.</small>
            </div>
      
            <template #footer>
              <Button label="Cancelar" icon="pi pi-times" class="p-button-secondary p-button-sm" @click="hideDialog" />
              <Button label="Salvar" icon="pi pi-check" class="p-button-success p-button-sm" @click="salvarTipoTensao" />
            </template>
          </Dialog>
  
          <!-- Modal deletar Unidade Tipo de tensão -->
          <Dialog v-model:visible="deleteProductDialog" :style="{ width: '450px' }" :modal="true">
            <div class="flex align-items-center justify-content-center">
              <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
              <span v-if="tiposTensao">Certeza que deseja apagar este item: <b>{{ tiposTensao.descricao }}</b>?</span>
            </div>
            <template #footer class="align-items-center">
              <Button label="Não" icon="pi pi-times" class="p-button-secondary p-button-sm" @click=" deleteProductDialog = false" />
              <Button label="Sim" icon="pi pi-check" class="p-button-danger p-button-sm" @click="apagarTipoTensao" />
            </template>
          </Dialog>
  
          <!-- Modal deletar vários selecionados -->
          <Dialog v-model:visible="deleteProductsDialog" :style="{ width: '450px' }" header="" :modal="true">
            <div class="flex align-items-center justify-content-center">
              <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
              <span v-if="tiposTensao">Apagar Tipo de tensão?</span>
            </div>
            <template #footer>
              <Button label="Não" icon="pi pi-times" class="p-button-text" @click="deleteProductsDialog = false" />
              <Button label="Sim" icon="pi pi-check" class="p-button-text" @click="deleteSelectedProducts" />
            </template>
          </Dialog>
  
        </div>
      </div>
    </div>
  </template>
  
  <script>
  
    import { FilterMatchMode } from "primevue/api";
  
    export default {
      data() {
        return {
          tiposTensoes: [],
          tiposTensao: {},
          selectUnidadesTipoTensao: null,
          selectedProducts: null,
          productDialog: false,
          tipos_tensao: [],
          deleteProductDialog: false,
          filters: {},
          submitted: false,
          deleteProductsDialog: false,
        
        };
      },
      // productService: null,
      created() {
        this.initFilters();
      },
      mounted() {
        this.ListarChaves();
        // this.listarTiposUnidadesTensao(); 
      },
  
  
      methods: {
        // listar unidades do end-point
        ListarChaves(){
          this.axios
            .post("/dicionario/listar-chaves")
            .then((response) => {
              this.tiposTensoes = response.data.dados;
            })
            .catch(() => {
              self.$toast.add({
                severity: "error",
                summary: "Ops!",
                detail: "Chamar o T.I.",
                life: 3000,
              });
            });
        },
  
        openNew() {
          this.tiposTensao = {};
          this.submitted = false;
          this.productDialog = true;
        },
  
        editProduct(tiposTensao) {
          this.productDialog = true;
          this.tiposTensao = { ...tiposTensao };
        },
  
      
  
        hideDialog() {
          this.productDialog = false;
          this.submitted = false;
        },
  
  
  
  
        // Cria / Editar  -> tipos de tensão
        salvarTipoTensao() {
          this.submitted = true;
  
          if (this.tiposTensao.id) {
            // Atualizar um tipos-tensão
            this.axios
              .post("/unidades/dados/tipos-tensao/editar", this.tiposTensao)
              .then(() => {
  
                this.productDialog = false;
                this.tiposTensao = {};
                this.ListarChaves();
              })
              .catch(() => {
                this.$toast.add({
                  severity: "error",
                  summary: "Ops!",
                  detail: "Chamar o T.I.",
                  life: 3000,
                });
              });
          } else {
            // Cadastrar novo tipos-tensão
            this.axios
              .post("/unidades/dados/tipos-tensao/adicionar", this.tiposTensao)
              .then(() => {
                this.productDialog = false;
                this.tiposTensao = {};
                this.ListarChaves();
              })
              .catch(() => {
                self.$toast.add({
                  severity: "error",
                  summary: "Ops!",
                  detail: "Chamar o T.I.",
                  life: 3000,
                });
              });
          }
        },
  
      
        //Apaga um tipo de tensão
        apagarTipoTensao() {
          this.axios
            .post("/unidades/dados/tipos-tensao/apagar", this.tiposTensao)
            .then(() => {
              
              this.deleteProductDialog = false;
              this.tiposTensao = {};
              this.$toast.add({
                severity: "success",
                summary: "Sucesso",
                detail: "Usuário apagado com sucesso!",
                life: 3000,
              });
              this.ListarChaves();
            })
            .catch(() => {
              
              self.$toast.add({
                severity: "error",
                summary: "Ops!",
                detail: "Chamar o T.I.",
                life: 3000,
              });
            });
        },
  
        //Apaga o Modal que abre ao selecionar que deseja apagar uma fatura
        apagarTipoTensaoModal(tiposTensao) {
          this.tiposTensao = tiposTensao;
          this.deleteProductDialog = true;
        },
  
        // botao de excluir selecionados
        confirmDeleteSelected() {
          this.deleteProductsDialog = true;
        },
  
        
        // Faz o delete de vários items selecionados no datatable
        deleteSelectedProducts() {
          this.tiposTensoes = this.tiposTensoes.filter(
            (val) => !this.selectUnidadesTipoTensao.includes(val)
          );
   
          let obj = this.selectUnidadesTipoTensao;
          Object.keys(obj).forEach((key) => {
            this.axios
              .post("/unidades/dados/tipos-tensao/apagar", obj[key])
              .then(() => {
                this.listarUsuario();
                this.deleteProductDialog = false;
                this.$toast.add({
                  severity: "success",
                  summary: "Sucesso",
                  detail: "Tipo tensão apagado com sucesso!",
                  life: 3000,
                });
              })
              .catch(() => {
                this.$toast.add({
                  severity: "error",
                  summary: "Ops!",
                  detail: "Chamar o T.I.",
                  life: 3000,
                });
              });
          });
   
          this.deleteProductsDialog = false;
          this.selectUnidadesTipoTensao = null;
          this.$toast.add({
            severity: "success",
            summary: "Sucesso",
            detail: "Products Deleted",
            life: 3000,
          });
        },
        /*
  
        */
       
       exportCSV() {
         this.$refs.dt.exportCSV();
        },
        
        
        initFilters() {
          this.filters = {
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
          };
        },
        
      },
    };
  
  
  </script>
  <style scoped lang="scss">
  @import "../../assets/demo/badges.scss";
  </style>
  